import { html, render, type PropertyValues } from 'lit';
import {
	customElement,
	property,
	query,
	queryAll,
	state,
} from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import { classMap } from 'lit/directives/class-map.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import AppBase from './app-base';
import { API } from '@API';
import { User } from '@app/User';
import { globals } from '@globals';
import { Router } from '@router';
import { initDEPRECATED } from './deprecated';
import { audioControls } from '../../app/AudioPlayer.js';
import { onLinkClick, internalLinkSelector } from '@utils';
import { matomoTracker } from '../../app/matomoTracker.js';
import '../../app/talent/components/dark-footer/index.js';
import '../../app/manage/components/setup-progress';
import '@frontend/yd-components/dist/components/yd-dialog/index.js';
import '@components/chat-launcher';
import './custom-events';
import { i18n } from '@i18n';

import type Template from '../../app/Template.js';
import type { NavigationScope } from 'src/@types/navigationScopes';
import type YdDialog from '@frontend/yd-components/dist/components/yd-dialog/yd-dialog';
import type {
	ShowMessageEventDetails,
	ShowMessageArguments,
	ShowMessageResolved,
} from './types';

const tagName = 'app-shell';
@customElement(tagName)
export default class AppShell extends AppBase {
	@property({ type: Object }) modal?: Template & { name: string };

	@property({ type: Object }) overlay?: (Template & { name: string }) | null;

	@state() scope: NavigationScope = 'default';

	/*
	 *	to be deprecated: elements should become components
	 */
	@query('header') headerElement!: HTMLElement;

	@query('main:not(.overlay) > section') mainElement!: HTMLElement;

	@query('main.overlay') overlayElement!: HTMLElement;

	@query('aside') asideElement!: HTMLElement;

	@query('.modal') modalElement!: HTMLElement;

	@queryAll(internalLinkSelector)
	nativeLinks!: HTMLCollection;

	static styles = [...AppBase.styles];

	async connectedCallback(): Promise<void> {
		super.connectedCallback();
		this.addListeners();
		try {
			matomoTracker.init();
		} catch {
			// let's continue without tracker then
		}

		let user;
		let error;
		try {
			({
				data: { user, error },
			} = await API.POST('/appinit'));
		} catch {
			user = {};
		}

		User.userObjectReceived({
			...user,
			...(error === 'tfarequired' ? { id: 'temp' } : {}),
		});

		const { isNewLanguage = false } = await i18n.setLanguage(
			User.preferredLanguage,
		);
		await initDEPRECATED(
			this,
			i18n,
			Router,
			globals.gui,
			User.device.hasTouchScreen,
		);

		if (isNewLanguage) {
			this.requestUpdate();
		}
		this.isLoading = false;

		if (error === 'tfarequired') {
			Router.navigate('/tfa');
		} else {
			Router.resolve();
		}
	}

	addListeners() {
		/*
		 * DEPRECATED needs to be refactored to dialog!
		 * Modal
		 */
		this.addEventListener('showModal', ({ detail }: CustomEvent) => {
			const { name, data } = detail;
			if (this.modal?.name !== name) this.modal?.destroy();
			import(`../../app/${import.meta.env.VITE_APP}/modal/${name}.js`).then(
				({ default: Modal }) => {
					this.modal = new Modal(this.modalElement, data);
					if (this.modal) {
						this.modal.name = name;
						this.modal?.init();
					}
				},
			);
		});
		this.addEventListener('destroyModal', () => {
			this.modal?.destroy();
			delete this.modal;
		});

		this.addEventListener('showOverlay', ({ detail }: CustomEvent) => {
			const { name, data = {} } = detail;
			if (this.overlay && this.overlay.name !== name) this.overlay.destroy();
			import(`../../app/${import.meta.env.VITE_APP}/pages/${name}.js`).then(
				({ default: Overlay }) => {
					this.overlay = new Overlay(
						this.overlayElement,
						null,
						null,
						this,
						name,
					);
					if (this.overlay) {
						this.overlay._rawData = {
							...this.overlay?._rawData,
							...data,
							name,
						};
					}
					this.overlay?.init();
				},
			);
		});
		this.addEventListener('destroyOverlay', () => {
			this.overlay?.destroy();
			this.overlay = null;
			this.requestUpdate();
		});

		/*
		 *	NavigationSwitch
		 */
		this.addEventListener(
			'switchNavigationScope',
			({ detail: { scope } }: CustomEvent) => {
				this.scope = scope;
			},
		);
	}

	async updated(_changedProperties: PropertyValues<this>) {
		super.updated(_changedProperties);
		if (
			!(_changedProperties.has('isLoading') && _changedProperties.size === 1) // do not rerender header and aside too much
		) {
			if (this.asideElement) {
				const { aside } = await import('@app/aside.js');
				render(aside(this.scope), this.asideElement);
			}
			if (this.headerElement) {
				const { header } = await import('@app/header.js');
				render(
					header(this.scope),

					this.headerElement,
				);
			}
		}

		[...this.nativeLinks].forEach((a) => {
			a.addEventListener('click', onLinkClick);
		});
	}

	// All dialogs should be part of their parent view like so:
	// ${when(
	// 	this.isDialogOpen,
	// 	() => html`
	// 		<yd-dialog
	// 			@@dismissed=${() => {}}
	// 			@confirmed=${() => {}}
	// 		></yd-dialog>
	// 	`
	// )}

	@property({ type: Object }) dialog!: ShowMessageArguments;
	showDialog(args: ShowMessageArguments): Promise<ShowMessageResolved> {
		return new Promise((resolve: (arg: ShowMessageResolved) => void) => {
			this.dialog = {
				...args,
				resolve,
			};
		});
	}

	onShowMessage({ detail }: { detail: ShowMessageEventDetails }) {
		if (detail.variant === 'danger') {
			this.dialog = {
				html: html`
					${unsafeHTML(detail.str)}
				`,
				noDismissButton: true,
				variant: detail.variant,
				titleText: window.T.term.error,
			};
		} else {
			super.showSnackBar(detail);
		}
	}
	@query('yd-dialog') dialogElement!: YdDialog;
	renderDialog() {
		return html`
			<yd-dialog
				@opened=${() => {
					this.dialog?.didOpen?.(this.dialogElement);
				}}
				@dismissed=${() => {
					this.dialog?.resolve?.({ isDismissed: true });
					this.dialog = null;
				}}
				@confirmed=${(e: CustomEvent) => {
					let confirmValue = e.detail?.value || {};
					if (this.dialog?.preConfirm) {
						confirmValue = this.dialog.preConfirm(this.dialogElement);
						if (confirmValue === false) {
							return;
						}
					}
					this.dialog?.resolve?.({ isDismissed: false, value: confirmValue });
					this.dialog = null;
				}}
				.scrollable=${typeof this.dialog?.scrollable === 'undefined' ||
				this.dialog?.scrollable}
				.variant=${this.dialog?.variant}
				.noDismissButton=${this.dialog?.noDismissButton}
				.noConfirmButton=${this.dialog?.noConfirmButton}
				.noEscape=${this.dialog?.noEscape}
				.size=${this.dialog?.size}
				.type=${this.dialog?.type}
				confirmButtonText=${this.dialog?.confirmButtonText || window.T.cta.ok}
				dismissButtonText=${this.dialog?.dismissButtonText ||
				window.T.cta.cancel}
			>
				${when(
					this.dialog?.titleText,
					() => html`
						<div slot="header">${this.dialog?.titleText}</div>
					`,
				)}
				${this.dialog?.html}
			</yd-dialog>
		`;
	}

	render() {
		return html`
			<article class=${classMap({ 'has-header': !!this.route.hasHeader })}>
				${when(
					(this.route.hasAsideNavigation && User.hasSession) ||
						(this.route.hasAsideSalesToolNavigation && User.hasSession),
					() => html`
						<aside></aside>
					`,
				)}
				${when(
					User.hasSession,
					() => html`
						<main class="overlay ${classMap({ open: !!this.overlay })}"></main>
					`,
				)}

				<main>
					<slot name="hubspot-chatbot"></slot>

					${when(
						globals.gui === 'manage' &&
							User.hasSession &&
							User.company?.wantsSetupProgress === 1,
						() => html`
							<setup-progress
								.currentRoute=${Router.current?.[0]?.url}
							></setup-progress>
						`,
					)}
					${when(
						globals.gui === 'talent' && User.hasSession && !User.isOnboarded,
						() => html`
							<dark-footer
								.obStep=${User.obStep}
								.showOnboarding=${this.route.as !== 'onboarding'}
							>
								<track-link
									slot="onboarding"
									href="onboarding/${User.obStep}"
									.track=${{ name: 'ob.join.getready' }}
									class="btn btn-emphasize btn-flat ms-3"
								>
									${window.T.nav.onboarding}
								</track-link>
							</dark-footer>
						`,
					)}
					${audioControls}
					<section
						class=${classMap({
							'no-padding':
								!this.route.hasHeader && !this.route.hasAsideNavigation,
						})}
					>
						${when(
							this.isLoading,
							() => html`
								<div class="loading-bar"></div>
							`,
						)}
						<slot></slot>
						<div id="page"></div>
					</section>
				</main>
				${when(
					User.hasSession && window.appElement.route.hasRocketchat,
					() => html`
						<chat-launcher
							.collisionPrevention=${User.isSelfservice}
						></chat-launcher>
					`,
				)}
			</article>

			${when(
				this.route.hasHeader && User.hasSession,
				() => html`
					<header></header>
				`,
			)}

			<div class="modal"></div>

			${when(this.dialog, () => this.renderDialog())}
			<!---->
			${this.renderSnackBar()}
		`;
	}
}

declare global {
	interface HTMLElementTagNameMap {
		'app-shell': AppShell;
	}
}
