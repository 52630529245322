import { User } from '@app/User';
import { PageBase } from '@components/page-base';
import { globals } from '@globals';
import { html, render } from 'lit';
import { Log } from '@modules/Log';
import { socket } from '@modules/Socket';
import { matomoTracker } from '../app/matomoTracker';
import { checkAuth } from './routeBeforeHooks';
import { confirmNavigation } from './routeLeaveHooks';
import { getRouteFromMatch } from './utils/getRouteFromMatch';
import { isUpdateAvailable } from './utils/isUpdateAvailable';
import { Router } from './';

const modules = import.meta.glob(
	['@app/pages/*.js', '@app/views/**/index.ts', '../views/**/index.ts'],
	{
		import: 'default',
	},
);

export const importPage = async (ydRoute: Route, old = false) => {
	let viewModule;
	const pathToLegacyModule = `/app/${import.meta.env.VITE_APP}/pages/${ydRoute.as}.js`;
	const pathToViewModule = ydRoute.isCommonRoute
		? `/views/${ydRoute.as}/index.ts`
		: `/app/${import.meta.env.VITE_APP}/views/${ydRoute.as}/index.ts`;
	const finalPath =
		ydRoute.isLegacyTemplate || old ? pathToLegacyModule : pathToViewModule;
	try {
		viewModule = await modules[finalPath]();
	} catch (err) {
		Log.error(err as Error);
	}

	return viewModule || null;
};

const createPageComponent = (match: Match) => {
	const { ydRoute, params, query } = getRouteFromMatch(match);
	const pageComponentElement = document.createElement(
		`page-${ydRoute.as.split('/').reverse()[0]}`,
	) as PageBase;
	pageComponentElement.params = params;
	pageComponentElement.query = query;
	if (ydRoute.hasCaptcha) {
		const captchaContainer = pageComponentElement || window.appElement;
		render(
			html`
				<div
					id="captcha"
					slot="captcha"
				></div>
			`,
			captchaContainer,
		);
	}
	return pageComponentElement;
};

export const commonDefaultRoute: Route = {
	as: 'home', // smart fallback?
	renderPage: true,
	hasHeader: true,
	hasAsideNavigation: true,
	isAuthRequired: true,
	isCommonRoute: false,
	isAllowedWithoutVerifiedEmail: false,
	isLegacyTemplate: false,
	hasCaptcha: false,
	hasRocketchat: false,
	hooks: {
		before: async (done: (arg1: boolean) => void, match: Match) => {
			if (isUpdateAvailable()) {
				done(false);
				window.location.href = `${document.baseURI}${match.url}`;
				return;
			}
			const { ydRoute, query } = getRouteFromMatch(match);
			const canNavigate = ydRoute && checkAuth(ydRoute, Router.navigate);

			if (canNavigate && !ydRoute.renderPage) {
				done(true);
				return;
			}

			// "preload" to abort the navigation in case the imported code errors
			ydRoute.templateClass = await importPage(
				ydRoute,
				Object.hasOwn(query, 'old'),
			);

			done(canNavigate && !!ydRoute.templateClass);
		},
		after: (match: Match) => {
			const {
				ydRoute: { hasPageTracking },
			} = getRouteFromMatch(match);
			if (hasPageTracking) matomoTracker.pageTrack();

			if (globals.hasYoummdaySocket) {
				const socketAuth = User.company
					? User.company?.websocket
					: User.user?.websocket;

				// no token or user has switched company
				if (
					!!socket.instance &&
					(!socketAuth || (socketAuth && socket.auth !== socketAuth))
				) {
					socket.kill();

					// let socket restart by setting new auth token
					if (socketAuth) socket.auth = socketAuth;
				}

				if (socketAuth && !socket.instance) {
					socket.initAndAuthorize(socketAuth);
				}
			}
		},
		leave: (canLeave) => confirmNavigation(canLeave),
	},
	uses: async (match: Match) => {
		const { ydRoute, params, query } = getRouteFromMatch(match);
		if (!ydRoute?.renderPage || !ydRoute?.templateClass || !window.appElement) {
			return;
		}

		const [lastResolved] = Router.lastResolved() || [];
		const isReInitialiasing =
			// completely new named route
			window.appElement?.route?.as !== ydRoute.as ||
			// or url params changed
			lastResolved?.url !== match.url;

		const container = ydRoute.isLegacyTemplate
			? window.appElement.mainElement.children?.page
			: window.appElement;
		if ((isReInitialiasing && ydRoute.as !== '') || ydRoute.as === 'login') {
			window.appElement.route?.template?.destroy?.();
			[...window.appElement.children].forEach((child) => {
				if (child.tagName?.startsWith('PAGE-')) {
					child.remove();
				}
			});
		}

		if (ydRoute.isLegacyTemplate) {
			const template = new ydRoute.templateClass(container, params, query);
			await template.init(!isReInitialiasing); // true = no rendering (yet)
			ydRoute.template = template;
		} else {
			const pageComponentElement = createPageComponent(match);
			render(pageComponentElement, container);
			ydRoute.template = pageComponentElement;
		}
		// eslint-disable-next-line require-atomic-updates
		window.appElement.route = ydRoute;
	},
};
