import { User } from '@app/User';
import type Navigo from 'navigo';

export const checkAuth = (
	ydRoute: Route,
	navigate: Navigo['navigate'],
): boolean => {
	const auth =
		!ydRoute.isAuthRequired || (ydRoute.isAuthRequired && User.hasSession);
	if (!auth) {
		navigate('/login', {
			updateBrowserURL: false,
		});
	}
	return auth;
};
