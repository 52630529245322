import { Log } from '@modules/Log';
import type languageJSON from '../srcs/en.json';

export const recursiveJSONProxy = (parent: typeof languageJSON) => {
	const proxy = new Proxy(parent, {
		get: (target, prop) => {
			if (
				// lit pollutes Objects with undefined properties; exclude these cases
				typeof prop === 'string' &&
				!prop.startsWith('_$lit') &&
				prop !== 'nodeType' &&
				(typeof target[prop] === 'undefined' ||
					typeof target[prop] === 'object')
			) {
				const objectChainAsString = `${target.__path || ''}.${prop}`;
				let fallback;

				if (typeof target[prop] === 'undefined') {
					Log.error(
						new Error(`Object chain undefined: ${objectChainAsString}`),
					);
					// eslint-disable-next-line no-new-wrappers
					fallback = new String(objectChainAsString);
					fallback.__path = objectChainAsString;
					fallback.toString = () => objectChainAsString;
					fallback.valueOf = () => objectChainAsString;
					fallback.toSource = () => objectChainAsString;
				} else {
					fallback = {
						...target[prop],
						__path: objectChainAsString,
					};
				}
				return recursiveJSONProxy(fallback);
			}
			return target[prop];
		},
	});
	return proxy;
};
