import ModelClass from '../Model.js';

class ModelManage extends ModelClass {
	constructor() {
		super();

		this.storageWhitelist.set('modules', () => true);
		this.storageWhitelist.set('users', () => true);
		this.storageWhitelist.set('preselectlist', (paramsSent) =>
			['get', 'add', 'remove', 'list', 'setlist'].includes(paramsSent.action),
		);
		this.storageWhitelist.set(
			'training',
			(paramsSent) => paramsSent.action === 'list',
		);
		this.storageWhitelist.set(
			'teams',
			(paramsSent) => paramsSent.action === 'list' && !paramsSent.id,
		);
	}

	onAPIpriceCheckHighError(error, path, response) {
		return this.resendAfterForceDialog(error, path, response);
	}

	onAPIteamIdsError(error, path, response) {
		this.Storage.remove('teams');
		const { sent } = response;
		sent.teamIds = '';
		return this.fetch(path, sent);
	}
}

const Model = new ModelManage();
export { Model };
