import type {
	ShowMessageEventArguments,
	ShowToastMessageEventArguments,
	ShowMessageEventDetails,
	ShowToastMessageEventDetails,
} from './types';
const customEventDefaultConfig = {
	bubbles: true,
	composed: true,
};
const namespace = 'app-shell';

export const createAlertMessageEvent: (
	...args: ShowMessageEventArguments
) => CustomEvent<ShowMessageEventDetails> = (str, variant = 'success') =>
	new CustomEvent(`${namespace}-showMessage`, {
		detail: { str, variant },
		...customEventDefaultConfig,
	});

export const createRerenderEvent = (): CustomEvent =>
	new CustomEvent(`${namespace}-rerender`, customEventDefaultConfig);

export const createToastMessageEvent: (
	...args: ShowToastMessageEventArguments
) => CustomEvent<ShowToastMessageEventDetails> = (
	element,
	variant,
	onDismissed = () => {},
) =>
	new CustomEvent('showToast', {
		detail: { element, variant, onDismissed },
		...customEventDefaultConfig,
	});

declare global {
	interface HTMLElementEventMap {
		navigate: CustomEvent<string>;
		['app-shell-showMessage']: ReturnType<typeof createAlertMessageEvent>;
		destroyModal: CustomEvent;
		['app-shell-showToast']: ReturnType<typeof createToastMessageEvent>;
		destroyToast: CustomEvent;
		showOverlay: CustomEvent<object>;
		destroyOverlay: CustomEvent;
		switchNavigationScope: CustomEvent;
		['app-shell-rerender']: ReturnType<typeof createRerenderEvent>;
	}
}
