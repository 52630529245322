import { html, type TemplateResult } from 'lit';

export const br = (str: string): TemplateResult[] | string =>
	str
		? str.split(/(?:\r\n|\r|\n)/gu).map(
				(part) => html`
					${part}
					<br />
				`,
			)
		: '';

// replace %s% in string each with own function argument
export const substitutePlaceholder = (
	str: string = '',
	...replacements: (string | number)[]
): string =>
	str
		.split('%s%')
		.map((part, i) => `${part}${replacements[i] || ''}`)
		.join('');

export const downloadBlob = (
	blob: Blob = new Blob(),
	filename: string = 'file.txt',
	_blank: boolean = false,
): void => {
	if (!(blob instanceof Blob)) return;
	const exportUrl = URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.href = exportUrl;
	link.download = filename;
	link.target = _blank ? '_blank' : '';
	link.dispatchEvent(
		new MouseEvent('click', {
			bubbles: true,
			cancelable: true,
			view: window,
		}),
	);

	setTimeout(() => {
		URL.revokeObjectURL(exportUrl);
		link.remove();
	}, 100);
};

export const copyToClipboard = async (text: string): Promise<boolean> => {
	if (!navigator.clipboard) return false;
	await navigator.clipboard.writeText(text);
	return true;
};
