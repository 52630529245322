import createClient from 'openapi-fetch';
import { deepNestedObjectToQueryString } from '@utils';
import type { EndpointsWithErrorKeyInResponses } from './types';
import { baseUrls } from './constants';
import { handleAuthenticationError } from './middlewares';

const APIv2 = createClient({
	baseUrl: baseUrls.v2,
	credentials: 'include',
});

const APIv1 = createClient<EndpointsWithErrorKeyInResponses>({
	baseUrl: baseUrls.v1,
	credentials: 'include',
	bodySerializer: deepNestedObjectToQueryString,
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
	},
	// signal: AbortSignal.timeout(1),
});

const UploadAPIv1 = createClient<EndpointsWithErrorKeyInResponses>({
	baseUrl: baseUrls.v1,
	bodySerializer(body) {
		const formData = new FormData();
		Object.entries(body || {}).forEach(([k, v]) => {
			if (v && (typeof v === 'string' || v instanceof Blob)) {
				formData.append(k, v);
			}
		});
		return formData;
	},
	credentials: 'include',
});

APIv2.use(handleAuthenticationError);
APIv1.use(handleAuthenticationError);
UploadAPIv1.use(handleAuthenticationError);

export { APIv2, APIv1 as API, UploadAPIv1 as UploadAPI };
