// https://github.com/krasimir/navigo/blob/master/DOCUMENTATION.md
import Navigo from 'navigo';
import { html, render } from 'lit';
import { guiRoutes } from '@app/routes';
import { Model } from '@app/Model.js';
import { User } from '@app/User';
import { notfound } from './notfound';
import { commonDefaultRoute } from './defaultroute';
import { socket } from '@modules/Socket';
import { matomoTracker } from '../app/matomoTracker';
import { createAlertMessageEvent } from '@components/app-shell/custom-events';
import AppShell from '@components/app-shell/app-shell';

export const Router = new Navigo(import.meta.env.BASE_URL);
Router.notFound(notfound);

export const navigateSilent = (path: string) => {
	Router.navigate(path, {
		callHandler: false,
		callHooks: false,
	});
};

export const routes: { [path: string]: Route } = {
	'/login': {
		...commonDefaultRoute,
		as: 'login',
		hasHeader: false,
		hasAsideNavigation: false,
		isCommonRoute: true,
		isAuthRequired: false,
		hasPageTracking: true,
		hasCaptcha: true,
	},
	'/notfound': {
		...commonDefaultRoute,
		as: 'notfound',
		hasHeader: true,
		hasAsideNavigation: true,
		isCommonRoute: true,
		hasPageTracking: true,
	},
	'/pwreset/:token': {
		...commonDefaultRoute,
		as: 'pwreset',
		hasHeader: false,
		hasAsideNavigation: false,
		isCommonRoute: true,
		isAuthRequired: false,
		hasPageTracking: true,
	},
	'/tfa': {
		...commonDefaultRoute,
		as: 'tfa',
		isCommonRoute: true,
		hasPageTracking: true,
		isAllowedWithoutVerifiedEmail: true,
		/*
		 * we might be in a state where the user has a BE session,
		 * but FE doesn't know because it didn't receive an user object
		 * due to { error: 'tfarequired' }
		 * therefore, we need to set:
		 */
		isAuthRequired: false,
		hasCaptcha: true,
		hooks: {
			...commonDefaultRoute.hooks,
			leave: (canLeave) => {
				canLeave(true);
				commonDefaultRoute.hooks?.leave?.(canLeave);
				render(
					html`
						<!-- -->
					`,
					window.appElement.mainElement,
				);
			},
		},
	},
	'/webphone': {
		...commonDefaultRoute,
		as: 'webphone',
		isCommonRoute: true,
		hasAsideNavigation: false,
		hasHeader: false,
		hooks: {
			before: commonDefaultRoute.hooks?.before,
			after: () => {
				socket.kill();
			},
		},
	},
	'/worthknowing/:topic': {
		...commonDefaultRoute,
		as: 'worthknowing',
		isCommonRoute: true,
		hasAsideNavigation: true,
		hasHeader: true,
	},
	'/verify/:token': {
		as: 'verify',
		uses: async (match: Match) => {
			const { data } = match;
			const { token = '' } = data || {};
			if (!token) return;
			const { success } = await Model.data.verifymail({ token });
			if (success) {
				matomoTracker.conversionTrack('email-verification');
				AppShell.getElement()?.dispatchEvent(
					createAlertMessageEvent(window.T.alert.success.mail_verified),
				);
				User.user = { emailverified: 1 };
				Router.navigate('/');
			}
		},
	},
	'/mobiledocupload/:token': {
		...commonDefaultRoute,
		as: 'mobiledocupload',
		isCommonRoute: true,
		isAuthRequired: false,
		isAllowedWithoutVerifiedEmail: true,
		isAllowedWithoutOnboarding: true,
		hasPageTracking: true,
		hasHeader: false,
		hasAsideNavigation: false,
	},
	'/': commonDefaultRoute,
	...guiRoutes,
};

Router.on(routes);
