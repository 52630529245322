import { Model } from '@app/Model.js';
import { User } from '@app/User';
import { notification, br } from '@utils';
import { html, render } from 'lit';
import { globals } from '@globals';
import { Storage } from '@modules/Storage';
import { Log } from '@modules/Log';
import { i18n } from '@i18n';
import { createToastMessageEvent } from '@components/app-shell/custom-events';

type PushMsg = {
	id: string;
	title: string;
	text: string;
	answer?: 0 | 1;
	user?: APIcompany;
};

type SocketMessage = {
	type: 'push' | 'call' | 'callstate' | 'callqueue';
	error?: string;
	unreadmessages?: number;
	callId?: string;
	module?: {
		title: string;
	};
	user?: {
		company: string;
	};
	calling?: 0 | 1;
	connectionPhone?: 0 | 1;
	reason?: string;
	msgs?: PushMsg[];
};

interface Socket {
	instance?: WebSocket;
	errorcount: number;
	auth?: string;
	restart?: ReturnType<typeof setTimeout>;
	lastQueues: unknown;
	storage: Storage;
	seenPushes: string[];
	indicator: Element | null;
	onMessage: (arg0: MessageEvent<SocketMessage>) => void;
	initAndAuthorize: (auth?: string) => void;
	callEvent: (arg0: Partial<SocketMessage>) => void;
	callstateEvent: (arg0: Partial<SocketMessage>) => void;
	callqueueEvent: (arg0: Partial<SocketMessage>) => void;
	pushEvent: (arg0: Partial<SocketMessage>) => void;
	kill: () => void;
}

export const socket: Socket = {
	errorcount: 0,
	lastQueues: null,
	storage: new Storage('socket', 'local'),
	get seenPushes() {
		return this.storage.get('seenPushes') || [];
	},
	get indicator() {
		return window.appElement.renderRoot.querySelector('mail-indicator');
	},
	onMessage({ data }) {
		try {
			// @ts-expect-error
			data = JSON.parse(data);
		} catch (err) {
			Log.error(err as Error);
			return;
		}

		const { unreadmessages } = data || {};
		if (unreadmessages && Number.isInteger(unreadmessages)) {
			this.storage.set('msgs', unreadmessages);
			this.indicator?.setAttribute('counter', unreadmessages.toString());
		}
		if (data.error) {
			if (data.error === 'auth') {
				delete this.auth;
			}
			if (data.error === 'timeout') {
				this.errorcount += 1;
			}
			return;
		}

		switch (data.type) {
			case 'call':
				this.callEvent(data);
				break;
			case 'callstate':
				this.callstateEvent(data);
				break;
			case 'callqueue':
				this.callqueueEvent(data);
				break;
			case 'push':
				this.pushEvent(data);
				break;
		}
	},
	initAndAuthorize(auth) {
		clearTimeout(this.restart);
		if (!auth) return;
		this.auth = auth;
		this.instance = this.instance || new WebSocket(globals.yoummdaySocket);
		this.instance.onclose = () => {
			delete this.instance;
			clearTimeout(this.restart);
			this.errorcount += 1;
			if (this.auth) {
				this.restart = setTimeout(
					(self) => {
						self.initAndAuthorize(this.auth);
					},
					this.errorcount * 5000,
					this,
				);
			}
		};
		this.instance.onmessage = (data) => this.onMessage(data);
		this.instance.onerror = () => {
			this.errorcount += 1;
		};

		this.instance.onopen = () => {
			if ('Notification' in window && Notification.permission === 'default') {
				Notification.requestPermission();
			}
			this.errorcount = 0;
			this.instance?.send(`auth=${this.auth}`);
		};
	},

	callEvent(data) {
		if (this.storage.get('currentCallId') === data.callId) return;
		this.storage.set('currentCallId', data.callId);

		notification(
			window.T.message.event.call_new,
			`${data.user?.company} | ${data.module?.title}`,
			i18n.language,
		);

		window.appElement.dispatchEvent(
			new CustomEvent('showOverlay', {
				detail: {
					name: 'call',
					data: { call: data },
				},
			}),
		);
	},

	callstateEvent({ calling, reason = '', connectionPhone = 1 }) {
		if (!calling && reason && i18n.rawText?.alert.error.telephony[reason]) {
			Log.log('reason called failed', reason);
			if (connectionPhone === 0) {
				/*
				 * in this case, backend set the user to connectionPhone: 0, so we do the same
				 * mutate user object (see User.ts->set user())
				 */
				User.user = { connectionPhone };
				window.appElement.showDialog({
					confirmButtonText: window.T.cta.close,
					noDismissButton: true,
					variant: 'danger',
					titleText: window.T.term.error,
					html: html`
						${window.T.alert.error.telephony.NO_ANSWER}
						<br />
						<small>[${reason}]</small>
					`,
				});
				window.appElement.requestUpdate();
			} else if (reason !== 'NO_ANSWER') {
				window.appElement.showDialog({
					confirmButtonText: window.T.cta.close,
					noDismissButton: true,
					variant: 'danger',
					titleText: window.T.term.error,
					html:
						reason === 'UNALLOCATED_NUMBER' &&
						User.user.connectionType !== 'webphone'
							? window.T.alert.error.telephony.UNALLOCATED_NUMBER_sipphone
							: html`
									${window.T.alert.error.telephony[reason]}
									<br />
									<small>[${reason}]</small>
								`,
				});
			}
		}
	},

	pushEvent({ msgs = [] }) {
		if (
			!msgs.filter(
				(push) =>
					!push.answer && // not answered yet
					!this.seenPushes.includes(push.id), // not yet seen
			).length
		) {
			return;
		}

		const addToSeenPushes = (id: string) => {
			this.seenPushes.push(id);
			this.storage.push('seenPushes', id);
		};
		const singlePush = (push: PushMsg) => {
			const container = document.createElement('div');
			const react = async (e: MouseEvent) => {
				const btn = e.currentTarget as HTMLElement;
				e.preventDefault();
				const { pns = [] } = await Model.data.notificationanswer({
					answer: btn.dataset.answer,
					pnId: push.id,
				});
				const unanswered = pns.filter(({ answer }) => !answer);
				if (!unanswered.length) {
					container.dispatchEvent(
						new CustomEvent('destroyToast', { bubbles: true }),
					);
				} else {
					btn.closest('div')?.remove();
				}
				addToSeenPushes(push.id);
			};
			render(
				html`
					<div>
						<div style="display:flex; align-items:center;">
							<div>
								<user-thumb
									.user=${push.user || { ymdItself: true }}
									size="40"
								></user-thumb>
							</div>
							<div>
								<h5 style="font-size:1.1em; margin:0;">${push.title}</h5>
							</div>
						</div>
						<p>${br(push.text)}</p>
						${push.user && globals.gui === 'talent'
							? html`
									<p>
										<sl-button
											@click=${react}
											variant="danger"
											data-answer="no"
										>
											<iconify-icon
												icon="mdi-close-circle"
												slot="prefix"
											></iconify-icon>
											${window.T.cta.decline}
										</sl-button>
										<sl-button
											@click=${react}
											variant="success"
											data-answer="yes"
										>
											<iconify-icon
												icon="mdi-check-circle"
												slot="prefix"
											></iconify-icon>
											${window.T.cta.agree}
										</sl-button>
									</p>
								`
							: ''}
					</div>
				`,
				container,
			);
			return container;
		};
		notification(window.T.message.event.pushmsg, '', i18n.language);
		msgs.forEach((push) => {
			const container = singlePush(push);
			window.appElement.dispatchEvent(
				createToastMessageEvent(container, undefined, () => {
					addToSeenPushes(push.id);
				}),
			);
		});
	},

	callqueueEvent(data) {
		if (
			globals.gui === 'talent' &&
			window.appElement.route.template &&
			window.appElement.route.as === 'home'
		) {
			this.lastQueues = data;
			window.appElement.route.template.rawData = { fifos: data };
			window.appElement.route.template.render();
		}
	},

	kill() {
		this.errorcount = 0;
		delete this.auth;
		if (this.instance) this.instance.close();
	},
};
