import moment from 'moment-timezone';
import { Model } from '@app/Model.js';
import { API } from '@API';
import { socket } from '@modules/Socket';
import { i18n } from '@i18n';

export type DeviceType = 'mobile' | 'desktop';
type someAPIUser = APIuser | APItalent | APIcompany;

export class BaseUser {
	private _user?: someAPIUser | null;

	crossTabsCommunication = new BroadcastChannel('apiUserObject');

	constructor() {
		this.crossTabsCommunication.onmessage = ({
			data,
		}: MessageEvent<someAPIUser>) => {
			this._user = data;
		};
	}

	browserNotifications: NotificationPermission | null =
		'Notification' in window ? Notification.permission : null;

	systemzone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

	async userObjectReceived(user: someAPIUser): Promise<void> {
		this.user = { ...user };
		const { lang, timezone } = user;
		if (lang) await i18n.setLanguage(lang);
		if (timezone) moment.tz.setDefault(timezone);
	}

	logout(): void {
		window.Router.navigate('/login', {
			updateBrowserURL: false,
		});

		socket.kill();
		Model.cleanup();

		try {
			if (this.hasSession) API.POST('/logout');
		} catch {
			// BE API seems to know already the user is logged out
		}
		this._user = null;
	}

	get user() {
		return this._user as someAPIUser;
	}

	set user(propertiesToUpdate: Partial<someAPIUser>) {
		this._user = {
			...(this._user || ({} as someAPIUser)),
			...propertiesToUpdate,
		};
		this.crossTabsCommunication.postMessage(this._user);
	}

	get hasSession(): boolean {
		return !!this._user?.id;
	}

	get preferredLanguage(): string {
		return this.user?.lang || window.navigator.language || 'en';
	}

	get emailVerified(): boolean {
		return !!this.user.emailverified;
	}

	can(feature: string = ''): boolean {
		const { features = [] } = this.user;
		return features.includes(feature);
	}

	get ymmdTeam(): boolean {
		return (
			!!this.user.email &&
			/@(external\.|icon\.)?yoummday\.com$/u.test(this.user.email)
		);
	}

	get ymdMail(): string | undefined {
		return this.user?.ymdmail;
	}

	get device(): {
		type: DeviceType;
		hasTouchScreen: boolean;
	} {
		const type: DeviceType = window.innerWidth <= 768 ? 'mobile' : 'desktop';
		const hasTouchScreen =
			'ontouchstart' in document.documentElement &&
			navigator.maxTouchPoints > 1;
		return { type, hasTouchScreen };
	}
}
