import * as prismic from '@prismicio/client';
import moment from 'moment-timezone';
import 'moment/dist/locale/de.js';
import { Log } from '@modules/Log';
import { API } from '@API';
import { Storage } from '@modules/Storage';
import type languageJSON from './srcs/en.json';
import { recursiveJSONProxy } from './utils/recursiveJSONProxy';

const euVatIdRequired = [
	'AT',
	'BE',
	'BG',
	'CY',
	'CZ',
	'DK',
	'EE',
	'GR',
	'ES',
	'FI',
	'FR',
	'HR',
	'HU',
	'IE',
	'IT',
	'LT',
	'LU',
	'LV',
	'MT',
	'NL',
	'PL',
	'PT',
	'RO',
	'SE',
	'SI',
	'SK',
	'CH', // CH ?!?
];
const ustCountries = [...euVatIdRequired, 'DE', 'GB'];
const euCountries = [...euVatIdRequired, 'DE'];
const highlyDemandedLanguages = ['de', 'en', 'nl', 'fr', 'it', 'es'];

export class Internationalization {
	txt: typeof languageJSON | null = null;
	rawText?: typeof languageJSON;
	statics?: AppStatics;
	storage = new Storage('statics');
	language: SupportedLanguages = 'en';
	translations: Record<SupportedLanguages, typeof languageJSON | null> = {
		de: null,
		en: null,
	};
	prismicContent: typeof window.prismicContent | undefined;
	prismicClient: prismic.Client<
		prismic.PrismicDocument<Record<string, never>, string, string>
	> = prismic.createClient('ymmd');

	async fetchTextSources() {
		try {
			this.translations[this.language] = await import(
				`./srcs/${this.language}.json`
			);
			const {
				quick_tip: prismicQuickTips,
				information_card: prismicInfoCards,
				descriptions: prismicDescriptions,
				faq_group: prismicFaqGroups,
				worth_knowing: prismicWorthKnowings,
				insights: prismicInsights,
				news: prismicNews,
				yd_training: prismicTrainings,
			} = await import(`./srcs/${this.language}.prismic.ts`);

			this.prismicContent = {
				quickTips: Object.assign(
					{},
					...prismicQuickTips.map(({ uid, data }) => ({
						[uid]: data,
					})),
				),
				informationCard: Object.assign(
					{},
					...prismicInfoCards.map(({ uid, data }) => ({
						[uid]: data,
					})),
				),
				descriptions: Object.assign(
					{},
					...prismicDescriptions.map(({ uid, data }) => ({
						[uid]: Object.assign(
							{},
							...data.pair.map(
								({ key, title, description, public: visible }) => ({
									[key]: { title, description, visible },
								}),
							),
						),
					})),
				),
				faqGroup: Object.assign(
					{},
					...prismicFaqGroups.map(({ uid, data }) => ({
						[uid]: data,
					})),
				),
				worthKnowings: Object.assign(
					{},
					...prismicWorthKnowings.map(({ uid, data }) => ({
						[uid]: data,
					})),
				),
				insights: prismicInsights.map(({ data }) => data),
				news: prismicNews.map(({ data, uid, last_publication_date: date }) => ({
					...data,
					date,
					uid,
				})),
				trainings: prismicTrainings.map(
					({ data, last_publication_date: date }) => ({
						...data,
						date,
					}),
				),
			};
		} catch (err) {
			Log.error(err);
		}
	}

	sortCountryCodes(
		countries: Record<string, string> = {},
		countrycodes: Record<string, string[]> = {},
	) {
		return Object.fromEntries(
			Object.entries(countrycodes).sort(([aCountryKey], [bCountryKey]) =>
				countries[aCountryKey]
					? countries[aCountryKey].localeCompare(countries[bCountryKey])
					: -1,
			),
		);
	}

	async getTranslatedStaticDataFromBackend(isNewLanguage: boolean) {
		const returnAlways = {
			ustCountries,
			euCountries,
			euVatIdRequired,
			lang: this.language,
			highlyDemandedLanguages,
		};
		let data;
		if (this.storage.has('statics') && !isNewLanguage) {
			data = this.storage.get('statics');
		} else {
			try {
				({ data } = await API.POST('/statics', {
					body: {
						lang: this.language,
					},
				}));
				this.storage.set('statics', data);
			} catch {
				// nvm
			}
		}

		return {
			...(!data || data?.error ? {} : data),
			...returnAlways,
			countrycodes: this.sortCountryCodes(data?.countries, data?.countrycodes),
		};
	}

	isSupported(language: string): boolean {
		return Object.keys(this.translations).some((l) => l === language);
	}

	async setLanguage(incomingLanguage: string = this.language) {
		const wantedLanguage = incomingLanguage.substring(0, 2).toLowerCase();
		let isNewLanguage = false;
		if (this.isSupported(wantedLanguage)) {
			isNewLanguage = this.language !== wantedLanguage;
			this.language = wantedLanguage as SupportedLanguages;
		}
		moment.locale(this.language);

		if (!this.translations[this.language]) {
			await this.fetchTextSources();
		}
		this.rawText = this.translations[this.language] as typeof languageJSON;
		this.txt = recursiveJSONProxy(this.rawText || {});

		this.statics = await this.getTranslatedStaticDataFromBackend(isNewLanguage);

		return {
			txt: this.txt,
			prismicContent: this.prismicContent,
			isNewLanguage,
		};
	}
}
const i18n = new Internationalization();

export { i18n };
