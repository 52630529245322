/* eslint-disable no-undefined */
// https://openapi-ts.pages.dev/openapi-fetch/middleware-auth
import type { Middleware } from 'openapi-fetch';
import AppShell from '@components/app-shell';

export const handleAuthenticationError: Middleware = {
	async onResponse({ response, schemaPath }) {
		if (schemaPath === '/logout' || !response.ok) {
			return undefined;
		}
		if (!response.headers.get('content-type')?.includes('application/json')) {
			return undefined;
		}

		const { error } = await response.clone().json(); // https://openapi-ts.pages.dev/openapi-fetch/middleware-auth#handling-statefulness
		if (
			error === 'auth' || // BE APIv1
			error?.code === 'AUTHENTIFICATION_ERROR' // BE APIv2
		) {
			const appElement = AppShell.getElement();
			appElement?.showSnackBar?.({
				str: window.T.alert.error.auth,
				variant: 'danger',
			});
		}

		return undefined;
	},
};
