import { css, html, LitElement, unsafeCSS, type TemplateResult } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import style from './style.scss?inline';
import platformBackground from './assets/platform-background.svg';
import { Router } from '@router';

import '@shoelace-style/shoelace/dist/components/alert/alert.js';
import '@frontend/yd-components/dist/components/iconify-icon.js';

import type {
	ShowMessageEventDetails,
	ShowToastMessageEventDetails,
} from './types';
// eslint-disable-next-line no-duplicate-imports
import type SlAlert from '@shoelace-style/shoelace/dist/components/alert/alert.js';

export default class AppBase extends LitElement {
	static styles = [
		style,
		css`
			:host {
				background-image: ${unsafeCSS(`url(${platformBackground})`)};
			}
		`,
	];
	private static _instance?: LitElement;

	static getElement() {
		return document.getElementsByTagName(
			AppBase._instance?.tagName || '',
		)?.[0] as AppBase;
	}

	@property({ type: Boolean }) isLoading: boolean = true;

	@property({ type: Object }) route: Route | TalentRoute = {
		as: '',
		uses: () => {},
	};

	constructor() {
		super();
		if (AppBase._instance) {
			throw new Error(
				"Singleton classes can't be instantiated more than once.",
			);
		}
		AppBase._instance = this;

		this.addEventListener(`${this.localName}-rerender`, () => {
			this.requestUpdate();
		});

		this.addEventListener('navigate', ({ detail: newUrl }) => {
			Router.navigate(newUrl);
		});

		this.addEventListener('app-shell-showMessage', this.onShowMessage);

		this.addEventListener(
			'app-shell-showToast',
			({ detail }: CustomEvent<ShowToastMessageEventDetails>) => {
				this.showToast(detail);
			},
		);
		this.addEventListener('destroyToast', () => {
			const alert = document.body.querySelector('sl-alert');
			alert?.hide();
		});
	}

	showToast({ element, variant, onDismissed }: ShowToastMessageEventDetails) {
		const alert = document.createElement('sl-alert');
		alert.variant = variant || 'neutral';
		alert.closable = true;
		alert.append(element);

		this.append(alert);
		alert.addEventListener('sl-after-hide', () => {
			onDismissed();
			alert.remove();
		});
		return alert.toast();
	}

	onShowMessage({ detail }: CustomEvent<ShowMessageEventDetails>) {
		this.showSnackBar(detail);
	}
	@state() snackBarMessage?: {
		msg: string;
		variant: SlAlert['variant'];
		icon: string;
	} | null;
	@query('.snack-bar') snackBarElement!: SlAlert;
	showSnackBar({ str, variant = 'success' }: ShowMessageEventDetails) {
		if (!str) return;
		const escapeHtml = (txt: string) => {
			const div = document.createElement('div');
			div.textContent = txt;
			return div.innerHTML;
		};
		const icons: { [key: string]: string } = {
			danger: 'material-symbols:report-outline-rounded',
			primary: 'material-symbols:info-outline-rounded',
			success: 'material-symbols:check-circle-outline-rounded',
			warning: 'material-symbols:warning-outline-rounded',
		};

		this.snackBarMessage = {
			variant,
			icon: icons[variant],
			msg: escapeHtml(str),
		};
		this.snackBarElement.show();
	}
	renderSnackBar(): TemplateResult | string {
		const { variant = 'success', msg, icon = '' } = this.snackBarMessage || {};
		return html`
			<sl-alert
				class="snack-bar"
				.variant=${variant}
				.closable=${true}
				.duration=${3000}
				@sl-after-hide=${() => (this.snackBarMessage = null)}
			>
				<iconify-icon
					icon=${icon}
					width="22"
					slot="icon"
				></iconify-icon>
				${msg}
			</sl-alert>
		`;
	}
}
