import { serializationOfIterator } from '@utils';
import type { Internationalization } from '@i18n';
import type { Router } from '@router';
import type AppShell from './app-shell.js';

export const initDEPRECATED = async (
	appElement: AppShell,
	i18n: Internationalization,
	router: typeof Router,
	gui: string,
	isTouchDevice: boolean = false,
	// eslint-disable-next-line max-params
) => {
	// should/could be integrated in a <yd-form> component one day
	HTMLFormElement.prototype.serializeObject = function (convertType: boolean) {
		const formEntries = new FormData(this).entries();
		return serializationOfIterator(formEntries, convertType);
	};

	// should be moved to talent-only init as soon as we have the applications split
	let Tdna;
	if (gui === 'talent' && !isTouchDevice) {
		const { TypingDNA } = await import('@modules/dna.js');
		Tdna = new TypingDNA();
	}

	// backwards compatibility, should be imported where needed
	Object.assign(window, {
		T: i18n.txt,
		prismicContent: i18n.prismicContent,
		appElement,
		Router: router,
		Tdna,
	});

	// [data-for] and [name="tabs-*"] evt listening should be replaced with something new and <yd-tabs>
	appElement.shadowRoot?.addEventListener('change', (e) => {
		const target = e.target as HTMLInputElement;

		if (typeof target.name === 'string' && target.name?.startsWith('tab')) {
			appElement.shadowRoot
				?.querySelectorAll('[for^="tab"]')
				.forEach((forTab) => {
					const activateTab = appElement.shadowRoot?.querySelector(
						`#${forTab.getAttribute('for')}`,
					) as HTMLInputElement;
					if (activateTab && 'checked' in activateTab) {
						forTab.classList.toggle('active', activateTab.checked);
					}
				});
		}

		if (target.hasAttribute('data-for')) {
			const dataFor = target.getAttribute('data-for') as string;
			const checkboxes = appElement.shadowRoot?.querySelectorAll(
				dataFor,
			) as NodeListOf<HTMLInputElement>;
			checkboxes.forEach((checkbox) => {
				checkbox.checked = target.checked;
				checkbox.dispatchEvent(new Event('change', { bubbles: true }));
			});
		}
	});
};
