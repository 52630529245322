import { LitElement, html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { customElement, state } from 'lit/decorators.js';
import { globals } from '@globals';
import { Model } from '@app/Model';
import { RocketChatService } from './services/RocketChatService.js';
import { RocketChatSocketService } from './services/RocketChatSocketService.js';
import style from './style.scss?inline';

@customElement('chat-launcher')
export default class ChatLauncher extends LitElement {
	@state() unreadCount: number = 0;

	@state() collisionPrevention: boolean = false;

	token = '';

	interval: ReturnType<typeof setInterval> | null = null;

	static styles = [style];

	constructor() {
		super();

		({ token: this.token } = RocketChatService.getRocketChatProperties());
	}

	async refreshToken() {
		({ token: this.token } = (await RocketChatService.getAuth()) || {});

		const rcStorage = Model.Storage.get('rocketchat');
		Model.Storage.set(
			'rocketchat',
			Object.assign(rcStorage, {
				token: this.token,
			}),
		);

		return this.token;
	}

	rcOpen() {
		const rcUrl = `${globals.rocketChatApi}/home?resumeToken=${this.token}`;
		window.open(rcUrl, '_blank').focus();
	}

	async connectedCallback(): Promise<void> {
		super.connectedCallback();
		await RocketChatService.init();
		RocketChatSocketService.updateUnreadMessagesCount();
	}

	render() {
		return html`
			<div
				class="chat ${classMap({
					'prevent-collision': this.collisionPrevention,
				})}"
			>
				<sl-button
					@click=${() => {
						this.refreshToken();
						this.rcOpen();
					}}
					variant="default"
					size="large"
					class="fab"
					circle
				>
					<iconify-icon
						id="chaticon"
						class="chaticon"
						icon="mdi:chat-bubble-outline"
						width="1.4rem"
						height="1.4rem"
					></iconify-icon>
					<iconify-icon
						id="chaticon2"
						class="chaticon"
						icon="mdi:external-link"
						width="1.4rem"
						height="1.4rem"
					></iconify-icon>
				</sl-button>
				${isNaN(this.unreadCount) || this.unreadCount === 0
					? ''
					: html`
							<span>${this.unreadCount >= 99 ? '99+' : this.unreadCount}</span>
						`}
			</div>
		`;
	}
}
