const appToApiControllerMap = {
	manage: 'manage',
	talent: 'provider',
	onboarding: 'onboard',
	backoffice: 'admin',
};
const currentApp = import.meta.env.VITE_APP;

export const timeStampFormat = 'YYYY-MM-DD HH:mm:ss';
export const baseUrls = {
	v1: `${import.meta.env.VITE_API_HOST}${appToApiControllerMap[currentApp]}/`,
	v2: `${import.meta.env.VITE_API_HOST}v2/`,
};
